var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _c("button", {
              ref: "popupCloseButton",
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close"
              }
            })
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                attrs: { "data-vv-scope": "add-brand-tutorial-video" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.addBrandTutorialVideo($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("h3", [_vm._v("Title")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.brandTutorialVideoDetails.title,
                          expression: "brandTutorialVideoDetails.title"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has(
                          "add-brand-tutorial-video.title"
                        )
                      },
                      attrs: {
                        type: "text",
                        placeholder: "Add Video Title",
                        name: "title"
                      },
                      domProps: { value: _vm.brandTutorialVideoDetails.title },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.brandTutorialVideoDetails,
                            "title",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("h3", [_vm._v("Order")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.brandTutorialVideoDetails.order,
                          expression: "brandTutorialVideoDetails.order"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has(
                          "add-brand-tutorial-video.order"
                        )
                      },
                      attrs: {
                        type: "text",
                        placeholder: "Add Video Order",
                        name: "order"
                      },
                      domProps: { value: _vm.brandTutorialVideoDetails.order },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.brandTutorialVideoDetails,
                            "order",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("h3", [_vm._v("Description")]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.brandTutorialVideoDetails.description,
                          expression: "brandTutorialVideoDetails.description"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has(
                          "add-brand-tutorial-video.description"
                        )
                      },
                      attrs: {
                        type: "text",
                        placeholder: "Add Video Title",
                        name: "description"
                      },
                      domProps: {
                        value: _vm.brandTutorialVideoDetails.description
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.brandTutorialVideoDetails,
                            "description",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("h3", [_vm._v("Video Link")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.brandTutorialVideoDetails.video_url,
                          expression: "brandTutorialVideoDetails.video_url"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            required: true,
                            url: { require_protocol: true }
                          },
                          expression:
                            "{required: true, url: { require_protocol: true }}"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has(
                          "add-brand-tutorial-video.videoUrl"
                        )
                      },
                      attrs: {
                        type: "text",
                        placeholder: "Add Video URL",
                        name: "videoUrl"
                      },
                      domProps: {
                        value: _vm.brandTutorialVideoDetails.video_url
                      },
                      on: {
                        change: function($event) {
                          return _vm.getVideoId()
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.brandTutorialVideoDetails,
                            "video_url",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("h3", [_vm._v("Video ID")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.brandTutorialVideoDetails.video_id,
                          expression: "brandTutorialVideoDetails.video_id"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has(
                          "add-brand-tutorial-video.videoId"
                        )
                      },
                      attrs: {
                        type: "text",
                        disabled: "",
                        placeholder: "Add Video ID",
                        name: "videoId"
                      },
                      domProps: {
                        value: _vm.brandTutorialVideoDetails.video_id
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.brandTutorialVideoDetails,
                            "video_id",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ]),
                _c("input", {
                  staticClass: "btn-lg-green",
                  attrs: { type: "submit", disabled: _vm.submittingForm },
                  domProps: {
                    value: _vm.editBrandTutorialVideoFlag
                      ? "UPDATE TUTORIAL VIDEO"
                      : "ADD TUTORIAL VIDEO"
                  }
                })
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("img", { attrs: { src: "/img/Alerts/plusbig_icon.png" } }),
      _vm._v(" Brand Tutorial Video")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }