var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "white-bg green-border-box outer-box" }, [
    _c("div", { staticClass: "inner-box" }, [
      _c(
        "span",
        {
          staticClass: "title",
          attrs: { title: _vm.brandTutorialVideo.dataPoint }
        },
        [_vm._v(_vm._s(_vm.brandTutorialVideo.title))]
      ),
      _c(
        "span",
        { staticStyle: { "text-decoration": "none" } },
        [
          _c(
            "router-link",
            {
              attrs: { target: "_blank", to: _vm.brandTutorialVideo.videoUrl }
            },
            [_vm._v(" " + _vm._s(_vm.brandTutorialVideo.videoUrl))]
          )
        ],
        1
      ),
      _c("br"),
      _c("div", { staticClass: "text-left float-left" }, [
        _c(
          "button",
          {
            staticClass: "btn-negative",
            attrs: {
              "data-target": "#delete-brand-tutorial-video-popup",
              "data-toggle": "modal",
              type: "button"
            },
            on: {
              click: function($event) {
                return _vm.$emit(
                  "loadBrandTutorialVideoDetails",
                  _vm.brandTutorialVideo
                )
              }
            }
          },
          [_vm._v("DELETE")]
        )
      ]),
      _c("div", { staticClass: "text-right" }, [
        _c(
          "button",
          {
            staticClass: "btn-positive",
            attrs: {
              "data-target": "#edit-brand-tutorial-video-popup",
              "data-toggle": "modal",
              type: "button"
            },
            on: {
              click: function($event) {
                return _vm.$emit(
                  "loadBrandTutorialVideoDetails",
                  _vm.brandTutorialVideo
                )
              }
            }
          },
          [_vm._v("EDIT")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }