
















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class BrandTutorialVideoItem extends Vue {
    @Prop() brandTutorialVideo!: any
}
