



















import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import DeleteBrandTutorialVideoPopup from '@/components/SAdmin/DeleteBrandTutorialVideoPopup.vue'
import BrandTutorialVideoPopup from '@/components/SAdmin/BrandTutorialVideoPopup.vue'
import BrandTutorialVideoItem from '@/components/SAdmin/BrandTutorialVideoItem.vue'

@Component({
  components: {
    BrandTutorialVideoItem,
    DeleteBrandTutorialVideoPopup,
    BrandTutorialVideoPopup
  }
})
export default class BrandTutorialVideos extends Vue {
  public query = ''
  public limit = 500
  public offset = 0
  public actionCountry: any = null
  public brandTutorialVideos: any[] = []
  public totalCount = 0
  public busy: boolean = false
  public offerIdtoDelete: number = 0
  public offerLink: string = ''
  public offerIdToCraeteLink: number = 0
  public brandTutorialVideoDetails: {id: any, title: string, description: string, videoUrl: string, videoId: string} = {
    id: '',
    title: '',
    description: '',
    videoUrl: '',
    videoId: ''
  }

  createOfferLink (offer:any) {
    this.offerIdToCraeteLink = offer.id
  }

  setActiveBrandTutorialVideoDetails (brandTutorialVideo: any) {
    this.brandTutorialVideoDetails = brandTutorialVideo
  }

  getBrandTutorialVideos (clear = true) {
    this.busy = true
    this.$store.dispatch('getBrandTutorialVideos', { query: this.query, limit: this.limit, offset: this.offset }).then((response) => {
      if (clear) this.brandTutorialVideos = []
      this.brandTutorialVideos.push(...response.response.body.brandTutorialVideos)
      this.totalCount = response.response.body.count
      this.busy = false
    }, (response) => {
      this.busy = false
    })
  }

  resetBrandTutorialVideosList () {
    this.brandTutorialVideoDetails = {
      id: '',
      title: '',
      description: '',
      videoUrl: '',
      videoId: ''
    }
    this.brandTutorialVideos = []
    this.offset = 0
    this.getBrandTutorialVideos()
  }

  setOfferIdToDelete (offerId:number) {
    this.offerIdtoDelete = offerId
  }

  loadMore () {
    this.$data.offset += this.$data.limit
    this.getBrandTutorialVideos(false)
  }

  @Watch('query')
  onSearch () {
    Vue.common.delay(() => {
      this.offset = 0
      this.getBrandTutorialVideos()
    }, 500)()
  }

  created () {
    this.getBrandTutorialVideos()
    this.$store.dispatch('getAppSettingsKeyValuePair')
  }

  refresh () {
    this.offset = 0
    this.getBrandTutorialVideos()
  }

  get hasLoadMore (): boolean {
    return this.brandTutorialVideos.length < this.totalCount
  }
}
