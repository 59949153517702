var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "green-border-box in-padd white-bg" },
    [
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.brandTutorialVideos, function(brandTutorialVideo, index) {
          return _c(
            "div",
            {
              key: brandTutorialVideo.id,
              staticClass: "col-xs-6 col-sm-4 item-list"
            },
            [
              _c("BrandTutorialVideoItem", {
                attrs: { index: index, brandTutorialVideo: brandTutorialVideo },
                on: {
                  loadBrandTutorialVideoDetails:
                    _vm.setActiveBrandTutorialVideoDetails
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _c("div", { staticClass: "text-center" }, [
        _vm.hasLoadMore
          ? _c(
              "button",
              {
                staticClass: "btn-positive",
                attrs: { disabled: _vm.busy },
                on: { click: _vm.loadMore }
              },
              [_vm._v("Load more")]
            )
          : _vm._e()
      ]),
      !_vm.busy &&
      (!_vm.brandTutorialVideos || _vm.brandTutorialVideos.length == 0)
        ? _c("h3", { staticClass: "text-center mt20" }, [
            _vm._v("\n        No Brand Tutorial Videos Found.\n      ")
          ])
        : _vm._e(),
      _c("DeleteBrandTutorialVideoPopup", {
        attrs: {
          popupId: "delete-brand-tutorial-video-popup",
          brandTutorialVideoDetails: _vm.brandTutorialVideoDetails
        },
        on: { updateBrandTutorialVideosList: _vm.resetBrandTutorialVideosList }
      }),
      _c("BrandTutorialVideoPopup", {
        attrs: {
          editBrandTutorialVideoFlag: false,
          brandTutorialVideoDetails: _vm.brandTutorialVideoDetails,
          popupId: "add-brand-tutorial-video-popup"
        },
        on: { updateBrandTutorialVideosList: _vm.resetBrandTutorialVideosList }
      }),
      _c("BrandTutorialVideoPopup", {
        attrs: {
          editBrandTutorialVideoFlag: true,
          brandTutorialVideoDetails: _vm.brandTutorialVideoDetails,
          popupId: "edit-brand-tutorial-video-popup"
        },
        on: { updateBrandTutorialVideosList: _vm.resetBrandTutorialVideosList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }